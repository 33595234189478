$("._tab").click(function() {
    let parentBlock = $(this).parents("._tabs-parent")
    let tabId = $(this).attr("data-tab")
    $(parentBlock).find("._tab").removeClass("_active")
    $(this).addClass("_active")
    $(parentBlock).find(".tab-content").removeClass("_active")
    $(parentBlock).find(`.${tabId}`).addClass("_active")
})



$(".where-buy__filter-btn").click(function() {
    $(".where-buy__filter").toggleClass("_open")
})

$(document).mouseup( function(e){
    let div = $( ".where-buy__dropdown" );
    if ( !div.is(e.target)
        && div.has(e.target).length === 0 ) {
        if($(".where-buy__filter").hasClass("_open")){
            $(".where-buy__filter").removeClass("_open")
        }
    }
});


$(".footer-about__top").click(function() {
    $(this).siblings(".footer-about__bottom").slideToggle()
    $(this).parents(".footer-about").toggleClass("_open")
})

let videos = document.querySelectorAll(".m-video")

videos.forEach(video => {
    video.addEventListener('click',function(){
        video.play();
    },false);
    video.click();
    video.play();
})


