$(window).on('load resize', function(){
    if ($(window).width() <= 992) {
        $('.catalog-item .product-oe').insertAfter('.catalog-item .product-info');
    } else{
        $('.catalog-item .product-oe').insertAfter('.catalog-item .gallery');
    }
});

$(".m-search input").on("input",function () {
    let val = $(this).val()

    if(val.length > 0){
        $(this).parents(".m-search").addClass("_filled")
    }else{
        $(this).parents(".m-search").removeClass("_filled")
    }
})

$("._clear-search").click(function () {
    $(this).siblings("input").val("").trigger("input")
})

$(".m-table__th .sort-btn").click(function () {
    if(!$(this).hasClass("_active")){
        $(this)
            .parents("th")
            .siblings("th")
            .find(".sort-btn")
            .removeClass("_active")
        $(this).addClass("_active")
        return
    }
    if($(this).hasClass("_active")){
        if($(this).hasClass("_reverse")){
            $(this).removeClass("_reverse")
        }else{
            $(this).addClass("_reverse")
        }
    }
})
