$(".burger").click(function () {
    $(".header").toggleClass("_open")
    $("body").toggleClass("fixed-body")
})

$(".nav-item__show").click(function () {
    $(this).parents(".nav-item").addClass("_open")
})

$(".nav-item__back").click(function () {
    $(this).parents(".nav-item").removeClass("_open")
})

$(".catalog-group .more-btn").click(function () {
    $(this).parents(".catalog-group").toggleClass("_show-all-links")
})

$(".catalog-group__btn").click(function () {
    $(this).parents(".catalog-group").toggleClass("_open")
    $(this).parents(".catalog-group").find(".catalog-group__bottom").slideToggle()
})

$(".lang__show").click(function () {
    $(this).parents(".lang").toggleClass("_open")
})

$(document).mouseup( function(e){
    let div = $( ".lang" );
    if ( !div.is(e.target)
        && div.has(e.target).length === 0 ) {
        if($(div).hasClass("_open")){
            $(div).removeClass("_open")
        }
    }
});

let position = window.pageYOffset;

window.addEventListener('resize', () => {
    setHeaderVisible()
})
setHeaderVisible()



function setHeaderVisible() {
    let oldScrollY = 0;
    let header = document.querySelector(".header")

    if(!header){
        return
    }

    let headerHeight = header.getBoundingClientRect().height

    window.addEventListener('scroll', function(){
        let scrolled = window.pageYOffset || document.documentElement.scrollTop;
        let dY = scrolled - oldScrollY;

        if ( dY < 0 ){
            header.classList.remove('_hide');
        } else {
            header.classList.add('_hide');
        }

        if(scrolled>headerHeight){
            $(".header").addClass("_bg")
        }else{
            header.classList.remove('_hide');
            $(".header").removeClass("_bg")
        }

        oldScrollY = scrolled;
    });

}


$(".catalog-menu__tab").mouseover(function() {
    let parentBlock = $(this).parents("._tabs-parent")
    let tabId = $(this).attr("data-tab")
    $(parentBlock).find("._tab").removeClass("_active")
    $(this).addClass("_active")
    $(parentBlock).find(".tab-content").removeClass("_active")
    $(parentBlock).find(`.${tabId}`).addClass("_active")
})
