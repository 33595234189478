$("._toggle-filter").click(function () {
    $("body").toggleClass("_open-filter")
    $("body").toggleClass("fixed-body")
})

$(".filter-group__top").click(function () {
    $(this).parents(".filter-group").toggleClass("_active")
    $(this).siblings(".filter-group__bottom").slideToggle()
})

let filterSliders = document.querySelectorAll(".filter-slider")

filterSliders.forEach(slider => {
    const rangeSlider = slider.querySelector(`.range-slider`)
    const rangeOutput1 = slider.querySelector(`.filter-slider__output1`)
    const rangeOutput2 = slider.querySelector(`.filter-slider__output2`)
    const rangeOutputs = [rangeOutput1, rangeOutput2]

    let startValue1 = +slider.getAttribute("data-start")
    let startValue2 = +slider.getAttribute("data-start2")
    let min = +slider.getAttribute("data-min")
    let max = +slider.getAttribute("data-max")

    if (rangeSlider) {
        noUiSlider.create(rangeSlider, {
            start: [startValue1, startValue2],
            connect: true,
            step: 1,
            range: {
                'min': min,
                'max': max
            }
        });
    }
    if (rangeSlider) {
        rangeSlider.noUiSlider.on('update', function(values, handle) {
            rangeOutputs[handle].innerHTML = Math.round(values[handle])
        })
    }
})

$(".filter-select").each(function (i,select) {
    let toggle = $(select).find(".filter-select__show")
    let clearBtn = $(select).find(".filter-select__clear")
    let checkBoxes = $(select).find(".m-check input")

    $(clearBtn).click(function () {
        $(checkBoxes).each(function (i,check) {
            $(check).prop("checked",false).trigger("change")
        })
    })

    $(toggle).click(function () {
        $(select).toggleClass("_open")
    })

    $(document).mouseup( function(e){
        let div = $(select);
        if ( !div.is(e.target)
            && div.has(e.target).length === 0 ) {
            if($(div).hasClass("_open")){
                $(div).removeClass("_open")
            }
        }
    });
})

$(".filter-group__checks-group").each(function (i,group) {
    let checkBoxesCount = $(group).find(".m-check input").length
    let moreBtn = $(group).find(".more-btn")
    let countOutput = $(group).find("._count")

    console.log(checkBoxesCount)
    if(checkBoxesCount > 5){
        $(group).addClass("_a-lot-of")
    }else{
        $(group).removeClass("_a-lot-of")
    }

    $(moreBtn).click(function () {
        $(group).toggleClass("_show-all")
    })

    $(countOutput).html(checkBoxesCount - 5)

})


$(".country-select").each(function (i,select) {
    let toggle = $(select).find(".country-select__show")
    let current = $(select).find(".country-select__current")
    let item = $(select).find(".country-item")
    let selectedItem = $(select).find(".country-item._selected")

    if(selectedItem){
        setActiveItem($(selectedItem))
    }

    $(item).click(function () {
        setActiveItem($(this))
    })

    function setActiveItem(el){
        $(el).siblings(".country-item").removeClass("_selected")
        $(el).addClass("_selected")
        let val = $(el).attr("data-title")
        $(current).html(val)
        $(select).removeClass("_open")
    }

    $(toggle).click(function () {
        $(select).toggleClass("_open")
    })

    $(document).mouseup( function(e){
        let div = $(select);
        if ( !div.is(e.target)
            && div.has(e.target).length === 0 ) {
            if($(div).hasClass("_open")){
                $(div).removeClass("_open")
            }
        }
    });
})
