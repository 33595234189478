$(window).scroll(function(){
    setMainSlideMediaPosition()
});
$(window).resize(function(){
    setMainSlideMediaPosition()
});
setMainSlideMediaPosition()

function setMainSlideMediaPosition() {
    let scrollTop = $(document).scrollTop();
    $(".main-slide__media").css("padding-top",`${scrollTop}px`)
}

$(".qa-card__show").click(function () {
    $(this).parents(".qa-card").toggleClass("_active")
    $(this).siblings(".qa-card__hidden").slideToggle()
})
