let animatedElements = document.querySelectorAll('.animated');

let Visible = function(target) {
    // Все позиции элемента
    let targetPosition = {
            top: window.pageYOffset + target.getBoundingClientRect().top,
            left: window.pageXOffset + target.getBoundingClientRect().left,
            right: window.pageXOffset + target.getBoundingClientRect().right,
            bottom: window.pageYOffset + target.getBoundingClientRect().bottom
        },
        // Получаем позиции окна
        windowPosition = {
            top: window.pageYOffset,
            left: window.pageXOffset,
            right: window.pageXOffset + document.documentElement.clientWidth,
            bottom: window.pageYOffset + document.documentElement.clientHeight
        };

    if (targetPosition.bottom - 40 > windowPosition.top &&
        targetPosition.top + 40 < windowPosition.bottom) {
        target.classList.add("animated--active")
    } else {

    }
};

// Запускаем функцию при прокрутке страницы
window.addEventListener('scroll', function() {
    // Visible (element);
    initAnimElements()
});
initAnimElements()
// А также запустим функцию сразу. А то вдруг, элемент изначально видно
// Visible (element);

function initAnimElements() {
    animatedElements.forEach(el => {
        Visible(el)
    })
}
