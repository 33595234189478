setFirstBlockParallaxHeight()
$(window).resize(function(){
    setFirstBlockParallaxHeight()
});
function setFirstBlockParallaxHeight() {
    let img = $(".page-top2__cover-bg")
    let parentHeight = $(img).parents(".page-top2").innerHeight()

    $(img).css("height",`${parentHeight}px`)
}

