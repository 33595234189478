function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

const numbers = document.querySelectorAll('.js-animate-number');
numbers.forEach(number => {
    let num = number.textContent;
    number.setAttribute("num", num);
    number.textContent = '0';
    const speed = 500;

    ['scroll','DOMContentLoaded'].forEach( evt =>
        document.addEventListener(evt, function () {
            if(isInViewport(number)==1){
                const animate = () => {
                    const outerValue = +number.getAttribute('num');
                    const innerValue = +number.innerText;
                    const time = outerValue / speed;
                    if(innerValue < outerValue) {
                        number.innerText = Math.ceil(innerValue+time);
                        setTimeout(animate, 1);
                    }else{
                        number.innerText = outerValue;
                    }
                }
                animate();
            }}, {
            passive: true
        })
    );

})
