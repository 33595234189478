gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(MotionPathPlugin);
// gsap.registerPlugin(DrawSVGPlugin);

let car = document.querySelector(`#car`);

if(car){
    carAnimation()
}
function carAnimation() {
    let path = document.querySelector(`#car-path2`);
    let pathLength = path.getTotalLength();
    path.style.strokeDasharray = pathLength + ' ' + pathLength;
    path.style.strokeDashoffset = pathLength * -1;

    const carTl = gsap.timeline({
        scrollTrigger: {
            trigger: ".history-path__anim-target",
            start: "top 12% top",
            end: 'bottom 90% center',
            scrub: 0,
        },
        onUpdate: ()=>{
            setPathFilling()
        }
    })


    function setPathFilling(){
        let drawLength = pathLength * carTl.progress();
        path.style.strokeDashoffset = drawLength - pathLength
        console.log(carTl.progress())
    }


    carTl
        .from("#car", {
            ease: "none",
            immediateRender: true,
            motionPath: {
                path: "#car-path",
                align: "#car-path",
                alignOrigin: [0.5, 1],
                autoRotate: 90
            }
        })
}




let scaleImages = document.querySelectorAll(".js-scroll-scale-img img")
scaleImages.forEach(img => {
    gsap.to(img, {
        scale: 1,
        scrollTrigger: {
            trigger: img,
            start: 'top 30% top',
            // end: 'bottom -5%',
            scrub: true,
        }
    })
})

let pageTopSection = document.querySelector(".page-top")
if(pageTopSection){
    let title = pageTopSection.querySelector(".page-top__title")

    const tl = gsap.timeline({})
    tl.from('.page-top__line', {
        height: 0,
        duration: 1,
        ease: "ease",
    }).from('.page-top ._animate-text', {
        y: 50,
        opacity: 0,
        duration: 1,
        stagger: .2,
    },"<").to('.page-top__img img', {
        scale: 1,
        duration: 1,
    },"<")
}


let manufacture = document.querySelector(".manufacture__inner")
if(manufacture){
    gsap.from(manufacture, {
        scale: .71,
        scrollTrigger: {
            trigger: manufacture,
            start: 'top 70% top',
            end: 'bottom 70% bottom',
            scrub: 1
        }
    })
}

let guaranteeSteps = document.querySelectorAll(".guarantee-steps .step-item")
guaranteeSteps.forEach(step => {
    gsap.from(step, {
        xPercent: 150,
        opacity: .5,
        scrollTrigger: {
            trigger: step,
            // start: 'top  top',
            end: 'bottom 60% bottom',
            scrub: true,
        }
    })
})

let marketingCards = document.querySelectorAll(".marketing .marketing-card:nth-child(odd)")
marketingCards.forEach(card => {
    let img = card.querySelector(".marketing-card__img")
    gsap.from(img, {
        xPercent: 103,
        opacity: .5,
        ease: "sine.inOut",
        scrollTrigger: {
            trigger: card,
            start: 'top top',
            scrub: true,
        }
    })
})

let marketingCards2 = document.querySelectorAll(".marketing .marketing-card:nth-child(even)")
marketingCards2.forEach(card => {
    let img = card.querySelector(".marketing-card__img")
    gsap.from(img, {
        xPercent: -103,
        opacity: .5,
        ease: "sine.inOut",
        scrollTrigger: {
            trigger: card,
            start: 'top top',
            scrub: true,
        }
    })
})

let leadersCards = gsap.utils.toArray('.leader__items');

if(leadersCards.length>0 && window.innerWidth > 992){
    gsap.to(leadersCards, {
        yPercent: -60,
        delay: 0.2,
        scrollTrigger: {
            trigger: '.leader__wrap',
            start: 'top 20% top',
            end: `+=${leadersCards.length*1000}`,
            snap: 1,
            pin: true,
            scrub: true
        }
    })
}

let countItems = document.querySelectorAll(".about-count .count-item__content")
countItems.forEach(count => {
    gsap.from(count, {
        xPercent: 150,
        opacity: .5,
        scrollTrigger: {
            trigger: count,
            // start: 'top  top',
            end: 'bottom 60% bottom',
            scrub: true,
        }
    })
})
