window.addEventListener('DOMContentLoaded', () => {
    fix100vh();
    window.addEventListener('resize', () => {
        fix100vh();
        getScrollBarSize()
    })

    getScrollBarSize()


    $(".js-select").niceSelect()

    $(".tel-input input").intlTelInput({
        initialCountry: 'ru',
        utilsScript: "js/utils.js"
    })
})

function fix100vh() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
